<template>
  <BModal
    id="modalAdd"
    ref="modalAdd"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
    @hide="resetModal()"
  >
    <div class="text-black font-semibold text-xl">
      Tambah Kuisioner
    </div>
    <ValidationObserver
      ref="formRules"
      #default="{ invalid }"
    >
      <BForm>
        <BFormGroup
          label="Skill Role"
          class="mt-1 text-black font-medium"
        >
          <ValidationProvider rules="required">
            <BDropdown
              :text="textActive(defaultTabActive)"
              class="w-full hore"
              variant="outline-secondary"
              :disabled="source === 'edit'"
            >
              <BDropdownItem
                v-for="tab in tabs"
                :key="tab.value"
                v-model="defaultTabActive"
                :active="activeItem(tab.value)"
                link-class="flex justify-between items-center text-start text-black"
                class="wkwkkw"
                @click="onClick(tab)"
              >
                {{ tab.text }}
              </BDropdownItem>
            </BDropdown>
          </ValidationProvider>
        </BFormGroup>
        <BFormGroup
          label="Pertanyaan"
          class="mt-1 text-black font-medium"
        >
          <ValidationProvider rules="required">
            <BFormTextarea
              v-model="question"
              placeholder="Isi Pertanyaanmu"
            />
          </ValidationProvider>
        </BFormGroup>
        <div class="text-center my-2">
          <div class="flex justify-center">
            <BButton
              size="md"
              variant="outline-primary"
              class="mr-1"
              style="width: 161px"
              @click="$bvModal.hide('modalAdd')"
            >
              Batal
            </BButton>
            <BButton
              :disabled="invalid || loading.update || loading.create"
              size="md"
              :variant="question && 'primary'"
              style="width: 161px"
              block
              @click="onFinish"
            >
              <BSpinner
                v-if="loading.update || loading.create"
                small
              />
              Submit
            </BButton>
          </div>
        </div>
      </BForm>
    </ValidationObserver>
  </BModal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { komtimAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import { alertSuccess, alertError } from '@toast'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    defaultTabActive: {
      type: Number,
      required: true,
    },
    getTabsList: {
      type: Function,
      default: () => {},
    },
    question: {
      type: String,
      default: '',
    },
    questionId: {
      type: Number,
      default: 0,
    },
    source: {
      type: String,
      default: '',
    },
    resetModal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      defaultTabs: [{
        value: null,
        text: 'Skill Role',
        disabled: true,
      }],
      loading: {
        create: false,
        update: false,
      },
      tabName: '',
      alertSuccess,
      alertError,
    }
  },
  methods: {
    async onCreate() {
      this.loading.create = true
      const payload = {
        questioner: this.question,
        skill_id: this.defaultTabActive,
      }
      const url = '/v1/questioners/store'
      await komtimAxiosIns.post(url, payload)
        .then(res => {
          this.getTabsList(payload.skill_id)

          const text = 'Berhasil manambahkan data'
          this.alertSuccess(text)
          this.$bvModal.hide('modalAdd')
          this.loading.create = false
        })
        .catch(err => {
          this.loading.create = false
          this.alertError(err)
        })
    },
    async onUpdate() {
      this.loading.update = true
      const payload = {
        questioner: this.question,
        skill_id: this.defaultTabActive,
      }
      const url = `/v1/questioners/${this.questionId}/update`

      await komtimAxiosIns.put(url, payload)
        .then(res => {
          const { message } = res.data.meta
          this.getTabsList()

          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)
          this.$bvModal.hide('modalAdd')
          this.loading.update = false
        })
        .catch(err => {
          this.loading.update = false
          this.alertError(err)
        })
    },
    onFinish() {
      if (this.source === 'edit') {
        this.onUpdate()
      } else {
        this.onCreate()
      }
    },
    textActive() {
      return this.tabs.find(tab => tab.value === this.defaultTabActive).text
    },
    activeItem(value) {
      return value === this.defaultTabActive
    },
    onClick(data) {
      const { text, value } = data
      this.defaultTabActive = value
      this.tabName = text
    },
  },
}
</script>
