<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-1">
      Kuisioner
    </div>
    <div class="d-flex overflow-x-scroll overflow-y-hidden">
      <div
        v-for="tab in tabs"
        :key="tab.value"
      >
        <BFormGroup>
          <BButton
            :variant="defaultTabActive === tab.value ? 'primary' : 'not-primary'"
            class="text-nowrap mr-1"
            pill
            :pressed="defaultTabActive === tab.value"
            @click="selectTab(tab.value)"
          >
            {{ tab.text }}
          </BButton>
        </BFormGroup>
      </div>
    </div>
    <BRow class="justify-content-start my-1 ml-0">
      <BCol lg="5" class="d-flex rounded-lg align-items-center border search-bar">
        <span
          class="k-search-normal-1 h-100 font-bold pt-[7px] align-middle mx-[5px]"
          style="font-size: 20px"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Cari Pertanyaan"
          @input="searchData"
          class="border-0"
        />
      </BCol>
      <BCol lg="3" class="ml-auto d-flex justify-content-end">
        <BButton
          variant="primary"
          class="ml-auto"
          @click="handleAddQuestion('add')"
        >
          <strong>Tambah</strong>
        </BButton>
      </BCol>
    </BRow>
    <BRow class="p-[8px] mx-0 bg-[#F4F4F4]">
      <BCol
        cols="1"
        class="text-black text-base font-medium"
      />
      <BCol
        cols="1"
        class="text-black text-base font-medium"
      >
        No
      </BCol>
      <BCol
        cols="8"
        class="text-black text-base font-medium"
      >
        Pertanyaan
      </BCol>
      <BCol
        cols="2"
        class="text-black text-base font-medium"
      >
        Aksi
      </BCol>
    </BRow>
    <BOverlay
      :show="loading.table"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div id="table" style="height: calc(100vh - 340px); overflow-y: scroll;" @scroll="handleScroll">
        <div
          v-if="items.length === 0"
          class="text-center p-3"
        >
          Tidak ada data yang ditampilkan.
        </div>
        <Draggable
          v-else
          v-model="items"
          tag="ul"
          class="list-group list-group-flush cursor-move mb-2"
          @change="changePosition"
        >
          <transition-group name="fade">
            <BListGroupItem
              v-for="(item, idx) in items"
              :key="`item-${item.id}`"
              tag="li"
              class="list-dragdrop"
            >
              <div class="d-flex align-items-center">
                <BCol cols="1">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/element-3.svg"
                    alt="Komerce"
                  >
                </BCol>
                <BCol
                  cols="1"
                  class="text-black"
                >
                  <div>{{ idx + 1 }}</div>
                </BCol>
                <BCol
                  cols="8"
                  class="text-black p-0"
                >
                  <strong>{{ item.question }}</strong>
                </BCol>
                <BCol class="flex items-center cursor-pointer">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                    alt="Komerce"
                    class="mr-[5px]"
                    @click="getQuestionById({ id: item.id, source: 'edit' })"
                  >
                  <img
                    v-b-modal.modalDelete
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                    alt="Komerce"
                    @click="selectItem(item.id)"
                  >
                </BCol>
              </div>
            </BListGroupItem>
          </transition-group>
        </Draggable>
      </div>
    </BOverlay>
    <ModalDelete
      :id-item="idItem"
      :get-tabs-list="getTabsList"
      :default-tab-active="defaultTabActive"
    />
    <ModalAdd
      :tabs="tabs"
      :default-tab-active="defaultTabActive"
      :get-tabs-list="getTabsList"
      :question="question"
      :question-id="questionId"
      :source="source"
      :reset-modal="resetModal"
    />
  </BCard>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import Draggable from 'vuedraggable'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import ModalDelete from './ModalDelete.vue'
import ModalAdd from './Add.vue'

export default {
  components: {
    Draggable,
    ModalDelete,
    ModalAdd,
  },
  data() {
    return {
      tabs: [],
      tabActive: 0,
      defaultTabActive: 0,
      idItem: 0,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      loading: {
        table: false,
      },
      question: '',
      initialQuestion: '',
      questionId: 0,
      source: '',
      alertError,
      alertSuccess,
    }
  },
  watch: {
    tabActive() {
      this.getTabsList()
    },
  },
  created() {
    this.initialQuestion = this.question
  },
  mounted() {
    this.getTabsList()
  },
  methods: {
    async getTabsList(value) {
      const url = '/v1/skills/questioner'
      await komtimAxiosIns.get(url)
        .then(async res => {
          const { data } = res.data
          const dataTabs = data.map(item => ({
            value: item.id,
            text: item.name,
          }))
          this.tabs = dataTabs

          this.offset = 0
          this.loading.table = true

          const defaultTab = this.tabActive === 0 ? dataTabs[0].value : this.tabActive
          const paramsFromAdd = value !== undefined && value
          const skillId = paramsFromAdd ? value : defaultTab
          this.defaultTabActive = skillId
          const params = `offset=${this.offset}&limit=${this.limit}&keyword=${this.keyword}&skill_id=${skillId}`
          // eslint-disable-next-line no-shadow
          const url = `/v1/questioners?${params}`
          await komtimAxiosIns.get(url)
            // eslint-disable-next-line no-shadow
            .then(res => {
              // eslint-disable-next-line no-shadow
              const { data } = res.data
              this.items = data
              this.loading.table = false
              this.offset = data.length
              this.lastData = data.length < this.limit
            })
            .catch(err => {
              this.loading.table = false
              this.alertError(err)
            })
        })
        .catch(err => {
          this.alertError(err)
        })
    },
    async getNextData() {
      if (!this.lastData) {
        this.loading.table = true
        const params = `offset=${this.offset}&limit=${this.limit}&keyword=${this.keyword}&skill_id=${this.tabActive}`
        const url = `/v1/questioners?${params}`
        await komtimAxiosIns()
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading.table = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.loading.table = false
            this.alertError(err)
          })
      }
    },
    async changePosition(data) {
      const { oldIndex, element } = data.moved
      const payload = {
        target: 0,
        current: element.Order,
      }
      for (let i = 0; i < this.items.length; i += 1) {
        if (i === oldIndex) {
          payload.target = this.items[i].Order
        }
      }
      const url = `/v1/questioners/${element.id}/update-order`
      await komtimAxiosIns.put(url, payload)
        .then(res => {
          const { message } = res.data.meta
          this.getTabsList()

          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)
        })
        .catch(err => {
          this.loading.table = false
          this.alertError(err)
        })
    },
    async getQuestionById(value) {
      const { id, source } = value
      this.source = source
      const url = `/v1/questioners/${id}`
      await komtimAxiosIns.get(url)
        .then(res => {
          this.$bvModal.show('modalAdd')
          const { data } = res.data
          this.question = data.question
          this.questionId = data.id
        })
    },
    selectTab(value) {
      this.tabActive = value
      this.keyword = ''
    },
    selectItem(id) {
      this.idItem = id
    },
    searchData: _.debounce(function search() {
      this.getTabsList()
    }, 1000),
    handleAddQuestion(value) {
      this.source = value
      this.$bvModal.show('modalAdd')
    },
    resetModal() {
      this.question = this.initialQuestion
    },
    handleScroll() {
      const table = document.getElementById('table')
      if ((table.scrollTop === table.scrollHeight - table.offsetHeight) && !this.loading && !this.lastData) {
        this.getNextData()
      }
    },
  },
}
</script>

<style scoped>
.search-bar {
    width: 400px;
}
</style>
